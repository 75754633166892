import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import Button from 'reusecore/src/elements/Button';
import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  ImageWrapper,
} from './promotionBlock.style';

const PromotionBlock = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        promotionData {
          image {
            publicURL
          }
          slogan
          title
          text1
          text2
          lists {
            id
            text
          }
        }
      }
    }
  `);

  const {
    slogan,
    title,
    text1,
    text2,
    lists,
    image,
  } = data.charityJson.promotionData;

  return (
    <BlockWrapper id="ourCommunity">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h3" content={slogan} />
              <Heading  content={title} />
               <a href="tel:0130273902">
              <Button  title="Appeler le standard du cabinet"  />
              </a>
            <br/>
               <br/>
              <a href={text2}><Heading as="h3" content="standard du cabinet: 0130273902"/></a>
              <a href="tel:0616521053">
              <Button  className="NGUYEN" title="Appeler le Dr NGUYEN" />
              </a>
              <br/>
              <Item  ><a href="tel:0616521053">Dr NGUYEN : 0616521053</a></Item>
              <a href="tel:0669000635">
               <Button  className="PHILABOUT" title="Appeler le Docteur PHILABOUT" />
               </a>
               <br/>
              <Item  ><a href="tel:0669000635">PHILABOUT : 0669000635</a></Item>
           
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={image.publicURL} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

/*
<List>
                {lists.map(item => (
                 <Item key= {`list_key${item.id}`} ><a href={item.text}>{item.text}</a></Item>
                ))}
              </List>

   <a href="tel:0782460975">
               <Button className="NJIWA" title="Appeler le Docteur NJIWA" />
               </a>
               <br/>
              <Item  ><a href="tel:0782460975">Docteur NJIWA : 0782460975</a></Item>
              

*/


// PromotionBlock style props
PromotionBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// PromotionBlock default style
PromotionBlock.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PromotionBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default PromotionBlock;
